import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { LoginContext } from '../../hooks/context/LoginContext';

import Kpi from '../ui/Kpi';
import Section from '../ui/Section';
import MiDataTable from '../ui/MiDataTable';
import CalendarioYear from '../ui/CalendarioYear';
import swal from 'sweetalert';
import { Accordion, Card } from "react-bootstrap";
import { SRLWrapper } from 'simple-react-lightbox';
import MiSelect from '../formulario/MiSelect';
import Button from '../ui/Button';
import Modal from 'react-bootstrap/Modal';
import FormInput from '../formulario/FormInput';
import Informes from '../formulario/informes/Informes';
import { alertService } from '../../services/alertservice';
import Spinner from 'react-bootstrap/Spinner';


import "bootstrap/dist/css/bootstrap.min.css";
import FormRow from '../formulario/FormRow';

import { getApiUrl, getAhorayyyymmdd, axiosPost, getNumFromData, getNumFromDataTraPdtAsignar} from '../../helpers/helperApp';

function InicioAdmin(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    const{ admin, traduction, propiedades, confPantalla } = props;

    const { apiKey } = useContext(LoginContext);

    const [ loadingTable, saveLoadingTable] = useState(true)
    const [ datosTableBarcos, saveDatosTableBarcos ] = useState([]);
    const [ datosTablePlantillas, saveDatosTablePlantillas ] = useState([]);
    const [ valCalendarPlantillas, saveValCalendarPlantillas ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]);
    const [ acordeonPrin, saveAcordeonPrin ] = useState(null);
    const [ acordeonCalPlantillas, saveAcordeonCalPlantillas ] = useState(null);
    const [ acordeonCalTrabajos, saveAcordeonCalTrabajos ] = useState(null);

    const [ datosTableTrabajos, saveDatosTableTrabajos ] = useState([]);
    const [ valCalendarTrabajos, saveValCalendarTrabajos ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]);    

    const [ plantillasCorrectivos, savePlantillasCorrectivos ] = useState([]);
    const [ loadingPlantillasCorrectivos, saveLoadingPlantillasCorrectivos ] = useState(false);

    const [ valFec1, saveValFec1 ] = useState(getAhorayyyymmdd());
    const [ valTrabajador, saveValTrabajador ] = useState(cookies.get('idage'));
    const [ descripcionDocu, saveDescripcionDocu ] = useState("")
    // Tabla mantenimientos
    const [ idMantenimientos, saveIdMantenimientos ] = useState(0);
    const [ idOriMantenimientos, saveIdOriMantenimientos ] = useState(0);
        // Modal Mantenimientos
        const [ showModalMatenimientosPendientes, saveShowModalMantenimientosPendientes ] = useState(false);
    // Tabla mantenimientos correctivos
    const [ idMantenimientosCorrectivos, saveIdMantenimientosCorrectivos ] = useState(0);
        // Modal Mantenimientos correctivos
        const [ showModalMatenimientosCorrectivos, saveShowModalMantenimientosCorrectivos ] = useState(false);
    
    const[ isPlanPreventivo, saveIsPlanPreventivo] = useState(false);

    const[ checkear, saveCheckear ] = useState(false);
    const[ checkeando, saveCheckeando ] = useState(false);
    const[ errorCheckeando, saveErrorCheckeando ] = useState(false);
    const[ checkCentro, saveCheckCentro ] = useState(null);
    const[ checkCentroNom, saveCheckCentroNom ] = useState(null);
    const[ checkEstado, saveCheckEstado ] = useState(null);
    const[ checkEstadoNom, saveCheckEstadoNom ] = useState(null);
    const[ checkFecha, saveCheckFecha ] = useState(null);
    const[ checkFechaNom, saveCheckFechaNom ] = useState(null);
    const[ checkRealizado, saveCheckRealizado ] = useState(null);
    const[ checkRealizadoNom, saveCheckRealizadoNom ] = useState(null);

    useEffect( () => {                        
        
        if( cookies.get('defaultActiveKey') ){
            toogleAcordeon(cookies.get('defaultActiveKey'), "acordeonPrin")
        }  

        loadPlantillasCorrectivos();

        return () =>{               
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
            source3.cancel();
        } 
        
    },[])

    const handleFec1 = (e) => {
        const { name, value } = e.target;
        saveValFec1(value)        
    }
    const handleSelectTrabajador = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveValTrabajador(selectedItem.value);  
        }else{
            saveValTrabajador(0);
        }

    }

    const checkOt = async (e, rowData) => {

        // Reseteamos el control de fecha y ponemos por defecto el agente asociado al usuario que está logueado
        saveValFec1(getAhorayyyymmdd());  
        saveValTrabajador(cookies.get('idage'));

        const aux = admin.controles.plantillas;

        let style, id, id_ori, descripcion, recurrencia, estado, idest;

        if( rowData ){
            style = rowData.style;
            id = rowData.id;
            id_ori = rowData.id_ori;
            descripcion = rowData.equipo+" - "+rowData.descripcion;
            recurrencia = rowData.recurrencia;
            estado = rowData.estado;
            idest = rowData.idest;  
        }else{
            style = e.target.dataset.style;
            id = e.target.dataset.iddoc;
            id_ori = e.target.dataset.id_ori;
            descripcion = e.target.dataset.title;
            recurrencia = e.target.dataset.recurrencia;
            estado = e.target.dataset.estado;  
            idest = e.target.dataset.idest;      
        }

        saveIdMantenimientos(id);
        saveIdOriMantenimientos(id_ori);
        saveDescripcionDocu(descripcion);

        switch (style) {
            case "alert-secondary":
            case "":
                    // PLANTILLA. Acciones. VER PLANTILLA(id) Y CREAR OT
                    saveShowModalMantenimientosPendientes(true);
                    break                          
        }
    }

    const actionsModalMantenimientosPendientes = (action) => {
        
        switch(action) {
            case 0:
                    // Cancelar
                    saveShowModalMantenimientosPendientes(false);              
              break;
            case 1:
                    // Creamos la ot
                    createDoc(idMantenimientos, admin.controles.plantillas.plantilla.createot, admin.controles.plantillas.plantilla.swalerror.text.title);
              break;
            case 2:
                    // Ver plantilla en solo lectura
                    window.open("/documentos/"+idMantenimientos);
              break;             
          }
    }

    const checkTrabajo = async (e, rowData) => {
        
        const aux = admin.controles.trabajos;

        let style, id, tip_ori, id_tra, descripcion, pregunta, recurrencia, estado, idest;

        if( rowData ){
            style = rowData.style;
            id = rowData.id;
            tip_ori = rowData.tip_ori;
            id_tra = rowData.id_tra;
            descripcion = rowData.equipo+" - "+rowData.descripcion;
            recurrencia = rowData.recurrencia;
            estado = rowData.estado;
            idest = rowData.idest;                      
        }else{
            style = e.target.dataset.style;
            id = e.target.dataset.iddoc;
            tip_ori = e.target.dataset.tip_ori;
            id_tra = e.target.dataset.id_tra;
            descripcion = e.target.dataset.title;
            recurrencia = e.target.dataset.recurrencia;
            estado = e.target.dataset.estado;
            idest = e.target.dataset.idest;   
        }
        switch (style) {
            case "alert-dark":
                // MOSTRAR MENSAJE. DOCUMENTO ES ESTADO INCORRECTO. CONTACTE CON SOPORTE TECNICO.
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${aux.docestincorrecto.title}`, {autoClose: true});
                break
            case "":
            case "alert-secondary":           
                    // case gris -> OT SIN TRABAJO -> ACCIONES -> VER OT(id) Y CREAR TRABAJO
                    swal({
                        title: aux.otsintrabajo.swal.title,
                        text: aux.otsintrabajo.swal.text.title,
                        content: {
                            element : "h6",
                            attributes : {
                                innerHTML : descripcion,
                                style : "max-width:fit-content"
                            }
                        },
                        icon: "warning",
                        buttons: {
                            cancel : {text: aux.otsintrabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.otsintrabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                            crearyejecutar :  {text: aux.otsintrabajo.swal.btncrearyejecutar.title, className:'btn btn-flat btn-outline-success btnSwalSuccess'},
                            verot : {text: aux.otsintrabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            // Crear el trabajo
                            createDoc(id, aux.otsintrabajo.createot, aux.otsintrabajo.swalerror.text.title);
                        }else if( confirmacion == "crearyejecutar" ){
                                // Crear el trabajo y ejecutar
                            createDoc(id, aux.otsintrabajo.createot, aux.otsintrabajo.swalerror.text.title, true);                                               
                        }else if( confirmacion == "verot" ){
                            // Ver la ot
                            propiedades.history.push("/documentos/"+id);                    
                        }
                    })
                break
            case "alert-success":                
                    // case success -> TRABAJOS CORRECTIVOS/TRABAJOS PREVENTIVOS en estado REALIZADO                
                    if( style == "alert-success" ){
                        if( tip_ori == 'P' ){
                            pregunta = aux.trabajo.swal.text.trabrealizado.preventivo.title;
                        }else if( tip_ori == 'C' ){
                            pregunta = aux.trabajo.swal.text.trabrealizado.correctivo.title;
                        }                        
                    }
                    swal({
                        title: aux.trabajo.swal.title,
                        text: pregunta,
                        content: {
                            element : "h6",
                            attributes : {
                                innerHTML : descripcion,
                                style : "max-width:fit-content"
                            }
                        },
                        icon: "warning",
                        buttons: {
                            cancel : {text: aux.trabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.trabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                            verot : {text: aux.trabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            // Ver trabajo
                            propiedades.history.push("/documentos/"+id_tra);    
                        }else if( confirmacion == "verot" ){
                            // Ver la ot
                            propiedades.history.push("/documentos/"+id);                    
                        }
                    })
                break
            case "alert-primary":
            case "alert-warning":
            case "alert-danger":
                    // case primary -> TRABAJOS PREVENTIVOS -> ACCIONES -> VER TRABAJO(id_tra) Y VER OT(id)
                    // case warning -> TRABAJOS CORRECTIVOS -> ACCIONES -> VER TRABAJO(id_tra) Y VER OT(id)
                    // case danger -> TRABAJOS A REVISAR -> ACCIONES -> VER TRABAJO(id_tra) Y VER OT(id)
                    let buttons;
                    if(idest == 9){
                        buttons = {
                            cancel : {text: aux.trabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.trabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},                           
                            verot : {text: aux.trabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }else{
                        buttons = {
                            cancel : {text: aux.trabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.trabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},                            
                            btnejecutar : {text: aux.trabajo.swal.btnejecutar.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                            verot : {text: aux.trabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }
                    if( style == "alert-primary" ){
                        pregunta = aux.trabajo.swal.text.trabpreventivo.title;
                    }else if( style == "alert-warning" ){
                        pregunta = aux.trabajo.swal.text.trabcorrectivo.title;
                    }else if( style == "alert-danger" ){
                        pregunta = aux.trabajo.swal.text.trabrevision.title;
                    }
                    swal({
                        title: aux.trabajo.swal.title,
                        text: pregunta,
                        content: {
                            element : "h6",
                            attributes : {
                                innerHTML : descripcion,
                                style : "max-width:fit-content"
                            }
                        },
                        icon: "warning",
                        buttons: buttons
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            // Ver trabajo
                            propiedades.history.push("/documentos/"+id_tra);    
                        }else if( confirmacion == "verot" ){
                            // Ver la ot
                            propiedades.history.push("/documentos/"+id);                    
                        }else if( confirmacion == "btnejecutar" ){
                            // Ejecutar el wizard
                            propiedades.history.push("/documentostrab/"+id_tra); 
                        }
                    })
                break                      
        }
       
    }

    const createDoc = async(idplantilla, url, textError, goToTrabajo=false) => {

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idplantilla );
        data.append("fec1", valFec1 );
        data.append("idage", valTrabajador );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+url, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+url, data, source.token);

        if( typeof respuesta.data.errorot == "string" ){
            // Ya existe una ot
            alertService.error(`<a href='${window.location.origin}/documentos/${respuesta.data.idot}'>${respuesta.data.errorot}</a>`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            if( goToTrabajo ){
                // Crear trabajo y ejecutar
                // Así lo mantenemos en la misma pestaña y no habría que actualizar la tabla
                // propiedades.history.push("/documentosenc/"+respuesta.data.idClon); 
                // Así ejecutamos el wizard
                propiedades.history.push("/documentostrab/"+respuesta.data.idClon);
            }else{
                // Así lo mantenemos en la misma pantalla y no habría que actualizar la tabla
                propiedades.history.push("/documentos/"+respuesta.data.idClon); 
    
                // Así abrimos la ot en otra pestaña y recargamos la tabla para que se actualice el campo que indica si hay ot abierta
                // window.open("/documentosenc/"+respuesta.data.idClon);
                // loadDatosTable();    
            }
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${textError}`, {autoClose: false});                        
        }
        saveShowModalMantenimientosPendientes(false);
        saveShowModalMantenimientosCorrectivos(false);

    }


    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){
            case "acordeonPrin":
                if( acordeonPrin == id ){
                    saveAcordeonPrin(null);
                    cookies.set('defaultActiveKey', null, {path: '/'});
                }else{
                    saveAcordeonPrin(id);
                    cookies.set('defaultActiveKey', id, {path: '/'});
                }                
                break;
            case "acordeonCalPlantillas":
                if( acordeonCalPlantillas == id ){
                    saveAcordeonCalPlantillas(null);
                }else{
                    saveAcordeonCalPlantillas(id);
                }                
                break;
            case "acordeonCalTrabajos":
                if( acordeonCalTrabajos == id ){
                    saveAcordeonCalTrabajos(null);
                }else{
                    saveAcordeonCalTrabajos(id);
                }                
                break;
        }
    }

    const goToKpis = (e, rowData) => {    
        let idCentro = "";
        let ini = "";
        let fin = "";  
        idCentro = rowData.id_barco;
        if( document.querySelector("#kpiIni") ){
            ini = document.querySelector("#kpiIni").value;
        }
        if( document.querySelector("#kpiFin") ){
            fin = document.querySelector("#kpiFin").value;
        }
        if( idCentro != "" && ini != "" && fin != "" ){
            propiedades.history.push("/kpis/"+idCentro+"/"+ini+"/"+fin);
        }
    }

    const clickCorrectivo = async (e, rowData) => {

        saveCheckear(false);
        saveErrorCheckeando(false);

        // Reseteamos el control de fecha y ponemos por defecto el agente asociado al usuario que está logueado
        saveValFec1(getAhorayyyymmdd());  
        saveValTrabajador(cookies.get('idage'));

        if( rowData.idtip == 10 ){
            saveIsPlanPreventivo(true);
        }else{
            saveIsPlanPreventivo(false);
        }

        saveIdMantenimientosCorrectivos(rowData.id);
        saveDescripcionDocu(rowData.equipo+" - "+rowData.descripcion);    
        saveShowModalMantenimientosCorrectivos(true);

    }
    const actionsModalMantenimientosCorrectivos = (action) => {            

        switch(action) {
            case 0:
                    // Cancelar
                    saveShowModalMantenimientosCorrectivos(false);              
              break;
            case 1:
                    // Creamos el correctivo
                    createCorrectivo(idMantenimientosCorrectivos);
              break;
            case 2:
                    // Creamos el preventivo
                    createDoc(idMantenimientosCorrectivos, admin.controles.plantillas.plantilla.createot, admin.controles.plantillas.plantilla.swalerror.text.title);                    
              break;
            case 3:
                    // Ver plantilla en solo lectura
                    window.open("/documentos/"+idMantenimientosCorrectivos);
              break;             
            case 4:
                    // Chekear documento
                    checkearDocumento();
              break; 
          }

    }

    const checkearDocumento = async () => {
        saveCheckear(false);
        saveErrorCheckeando(false);
        saveCheckeando(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idMantenimientosCorrectivos );
        data.append("fecini", cookies.get('kpiIni') );
        data.append("fecfin", cookies.get('kpiFin') );
        data.append("idage", valTrabajador );        
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+admin.controles.createcorrectivo.checkear.getdata, data, source4.token);
        
        saveCheckeando(false);

        if( typeof respuesta.data[0] == "string" ){
            saveErrorCheckeando(respuesta.data);
        }else{
            saveCheckear(true);
            saveCheckCentro(respuesta.data[0].centro);
            saveCheckCentroNom(respuesta.data[0].centronom)
            saveCheckEstado(respuesta.data[0].estado);
            saveCheckEstadoNom(respuesta.data[0].estadonom);
            saveCheckFecha(respuesta.data[0].fecha);
            saveCheckFechaNom(respuesta.data[0].fechanom);
            saveCheckRealizado(respuesta.data[0].realizado);
            saveCheckRealizadoNom(respuesta.data[0].realizadonom);
        }


    }

    const createCorrectivo = async(id)=>{
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", id )
        data.append("fec1", valFec1 );
        data.append("idage", cookies.get('idage') );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+admin.controles.createcorrectivo.createcorrectivo, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+admin.controles.createcorrectivo.createcorrectivo, data, source2.token);

        if( typeof respuesta.data.errorot == "string" ){
            // Ya existe una ot
            alertService.error(`<a href='${window.location.origin}/documentos/${respuesta.data.idot}'>${respuesta.data.errorot}</a>`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            // Así lo mantenemos en la misma pantalla y no habría que actualizar la tabla
            propiedades.history.push("/documentos/"+respuesta.data.idClon); 
    
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${admin.controles.createcorrectivo.swalerror.text.title}`, {autoClose: false});             
        }     

        saveShowModalMantenimientosCorrectivos(false);    
    }


    const loadPlantillasCorrectivos = async() => {

        saveLoadingPlantillasCorrectivos(true);
        const data = new FormData();
        data.append("validacion", apiKey());       
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+admin.controles.createcorrectivo.datatable.getdata, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+admin.controles.createcorrectivo.datatable.getdata, data, source3.token);

        savePlantillasCorrectivos(respuesta.data);
        saveLoadingPlantillasCorrectivos(false);

    }

    return(
        <SRLWrapper>
        <Fragment>           
            <Section titulo={admin.controles.section.title} icono={admin.controles.section.icono}>
                <Kpi
                    conf={ admin.controles.kpi }
                    getDataTable1 = { admin.controles.barcos.datatable.getdata }
                    saveDataTable1 = { saveDatosTableBarcos }
                    getDataTable2 = { admin.controles.plantillas.datatable.getdata }
                    saveCalendar2 = { saveValCalendarPlantillas }
                    saveDataTable2 = { saveDatosTablePlantillas }
                    getDataTable3 = { admin.controles.trabajos.datatable.getdata }
                    saveCalendar3 = { saveValCalendarTrabajos}
                    saveDataTable3 = { saveDatosTableTrabajos }
                    saveLoadingTable = { saveLoadingTable }
                    />
                {
                    datosTableTrabajos != null
                    ?
                        <Fragment>
                            <div className="row" >
                                <div className="col-12">
                                    <div className="row justify-content-md-center"> 
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-primary alertHelp" role="alert">
                                                {getNumFromData(datosTableTrabajos, 'alert-primary')}{admin.controles.trabajos.help.primary.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-warning alertHelp" role="alert">
                                                {getNumFromData(datosTableTrabajos, 'alert-warning')}{admin.controles.trabajos.help.warning.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-danger alertHelp" role="alert">
                                                {getNumFromData(datosTableTrabajos, 'alert-danger')}{admin.controles.trabajos.help.danger.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            {/* <div className="alert alert-light alertHelp" role="alert" style={{borderColor: "#888888"}}> */}
                                            <div className="alert alert-secondary alertHelp" role="alert" >
                                                {getNumFromData(datosTableTrabajos, 'alert-secondary')}{admin.controles.trabajos.help.light.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-success alertHelp" role="alert">
                                                {getNumFromData(datosTableTrabajos, 'alert-success')}{admin.controles.trabajos.help.success.title}
                                            </div>
                                        </div>                                                          
                                    </div>
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"trabajosAdmin"}
                                    columnas={admin.controles.trabajos.datatable.columns}
                                    data={datosTableTrabajos}
                                    linkColumns={admin.controles.trabajos.datatable.linkColumns}
                                    buttons={admin.controles.trabajos.datatable.buttons}
                                    ordering={admin.controles.trabajos.datatable.ordering}
                                    order={admin.controles.trabajos.datatable.order}
                                    searching={admin.controles.trabajos.datatable.searching}
                                    paging={admin.controles.trabajos.datatable.paging}
                                    select={admin.controles.trabajos.datatable.select}
                                    pagelength={admin.controles.trabajos.datatable.pagelength}
                                    // handleEditReg= { prueba }
                                    // handleDeleteItem={ prueba }
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={admin.controles.trabajos.datatable.columnactions.title}
                                    traduction={traduction.datatable}
                                    functionClickTd={ checkTrabajo }
                                />                          

                                </div>
                                <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                    <Accordion /*defaultActiveKey="0"*/ >
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalTrabajos")}  className={ acordeonCalTrabajos == "0" ? "activeAcordeon" : "" } >
                                                <i className={ admin.controles.trabajos.calendario.acordeon.icono } ></i> { admin.controles.trabajos.calendario.acordeon.title }
                                                {
                                                    acordeonCalTrabajos == "0"
                                                    ?
                                                        <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                    :
                                                        <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                }                                                                  
                                            </Accordion.Toggle>
                                        </Card>
                                        <Accordion.Collapse eventKey="0" className={"slowAcordeon"}>
                                            <Card.Body>
                                                <CalendarioYear 
                                                    data = { valCalendarTrabajos }
                                                    locale = { cookies.get('idioma') }
                                                    onDayClick={ true }
                                                    colorTitleEvent={ true }
                                                    functionClickTitle = { checkTrabajo }
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </div>                                                    
                            </div>
                        </Fragment>
                    :
                        null
                }
            </Section>
                <Accordion defaultActiveKey={ cookies.get('defaultActiveKey') ? cookies.get('defaultActiveKey') : ""  }  >                    
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toogleAcordeon('1', "acordeonPrin")} className={ acordeonPrin == "1" ? "activeAcordeon" : "" }>
                            <i className={ admin.controles.plantillas.section.icono } ></i> { admin.controles.plantillas.section.titulo }
                            {
                                acordeonPrin == "1"
                                ?
                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                :
                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                            }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1" className={"slowAcordeon"} >
                            <Card.Body>
                            {
                                        datosTablePlantillas != null
                                        ?
                                            <Fragment>
                                                <div className="row" >
                                                    <div className="col-12">
                                                        {/* <div className="row"> 
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="alert alert-primary alertHelp" role="alert">
                                                                    {admin.controles.plantillas.help.primary.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="alert alert-warning alertHelp" role="alert">
                                                                    {admin.controles.plantillas.help.warning.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="alert alert-light alertHelp" role="alert" style={{borderColor: "#888888"}}>
                                                                    {admin.controles.plantillas.help.light.title}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    <div className="row justify-content-md-center"> 
                                                        {/* <div className="col-md-12 col-lg-2">
                                                            <div className="alert alert-primary alertHelp" role="alert">
                                                                {getNumFromData(datosTablePlantillas, 'alert-primary')}
                                                                {admin.controles.plantillas.leyendapendientes.preventivo.title}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-2">
                                                            <div className="alert alert-warning alertHelp" role="alert">
                                                                {getNumFromData(datosTablePlantillas, 'alert-warning')}
                                                                {admin.controles.plantillas.leyendapendientes.correctivo.title}
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-12 col-lg-2">
                                                            <div className="alert alert-danger alertHelp" role="alert">
                                                                {getNumFromDataTraPdtAsignar(datosTablePlantillas, true)}
                                                                {admin.controles.plantillas.leyendapendientes.excedido.title}
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-12 col-lg-2">                                                            
                                                            <div className="alert alert-secondary alertHelp" role="alert" >
                                                                {getNumFromData(datosTablePlantillas, 'alert-secondary')}
                                                                {admin.controles.plantillas.leyendapendientes.sinplazo.title}
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-12 col-lg-2">
                                                            <div className="alert alert-success alertHelp" role="alert">
                                                                {getNumFromDataTraPdtAsignar(datosTablePlantillas, false)}
                                                                {admin.controles.plantillas.leyendapendientes.enplazo.title}
                                                            </div>
                                                        </div>                                                       
                                                    </div>
                                                    <MiDataTable 
                                                        estadoForm= { "" }
                                                        formulario={ {id:0} }
                                                        id={"plantillasAdmin"}
                                                        columnas={admin.controles.plantillas.datatable.columns}
                                                        data={datosTablePlantillas}
                                                        linkColumns={admin.controles.plantillas.datatable.linkColumns}
                                                        buttons={admin.controles.plantillas.datatable.buttons}
                                                        ordering={admin.controles.plantillas.datatable.ordering}
                                                        order={admin.controles.plantillas.datatable.order}
                                                        searching={admin.controles.plantillas.datatable.searching}
                                                        paging={admin.controles.plantillas.datatable.paging}
                                                        select={admin.controles.plantillas.datatable.select}
                                                        pagelength={admin.controles.plantillas.datatable.pagelength}
                                                        // handleEditReg= { prueba }
                                                        // handleDeleteItem={ prueba }
                                                        // saveLoadingTable={ saveLoadingTable }
                                                        loadingTable={loadingTable}
                                                        columnactions={admin.controles.plantillas.datatable.columnactions.title}
                                                        traduction={traduction.datatable}
                                                        functionClickTd={ checkOt }
                                                    />                          

                                                    </div>
                                                    <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                                        <Accordion /*defaultActiveKey="0"*/ >
                                                            <Card>
                                                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalPlantillas")} className={ acordeonCalPlantillas == "0" ? "activeAcordeon" : "" } >
                                                                    <i className={ admin.controles.plantillas.calendario.acordeon.icono } ></i> { admin.controles.plantillas.calendario.acordeon.title }
                                                                    {
                                                                        acordeonCalPlantillas == "0"
                                                                        ?
                                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                                        :
                                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card>
                                                            <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                                                <Card.Body>
                                                                    <CalendarioYear 
                                                                        data = { valCalendarPlantillas }
                                                                        locale = { cookies.get('idioma') }
                                                                        onDayClick={ true }
                                                                        colorTitleEvent={ true }
                                                                        functionClickTitle = { checkOt }
                                                                    />
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => toogleAcordeon('2', "acordeonPrin")} className={ acordeonPrin == "2" ? "activeAcordeon" : "" } >
                           <i className={ admin.controles.createcorrectivo.section.icono } ></i> { admin.controles.createcorrectivo.section.title }
                           {
                                acordeonPrin == "2"
                                ?
                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                :
                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                            }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2" className={"slowAcordeon"}>
                            <Card.Body>
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"createcorrectivo"}
                                    columnas={admin.controles.createcorrectivo.datatable.columns}
                                    data={plantillasCorrectivos}
                                    linkColumns={admin.controles.createcorrectivo.datatable.linkColumns}
                                    buttons={admin.controles.createcorrectivo.datatable.buttons}
                                    ordering={admin.controles.createcorrectivo.datatable.ordering}
                                    order={admin.controles.createcorrectivo.datatable.order}
                                    searching={admin.controles.createcorrectivo.datatable.searching}
                                    paging={admin.controles.createcorrectivo.datatable.paging}
                                    select={admin.controles.createcorrectivo.datatable.select}
                                    pagelength={admin.controles.createcorrectivo.datatable.pagelength}
                                    saveLoadingTable={ saveLoadingPlantillasCorrectivos }
                                    loadingTable={ loadingPlantillasCorrectivos }
                                    columnactions={admin.controles.createcorrectivo.datatable.columnactions.title}
                                    traduction={traduction.datatable}
                                    functionClickTd={ clickCorrectivo }
                                />    
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonPrin")} className={ acordeonPrin == "0" ? "activeAcordeon" : "" }>
                            <i className={ admin.controles.barcos.section.icono } ></i> { admin.controles.barcos.section.titulo }
                            {
                                acordeonPrin == "0"
                                ?
                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                :
                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                            }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                            <Card.Body>
                                {
                                    datosTableBarcos != null
                                        ?
                                            <Fragment>
                                                <div className="row" >
                                                    <div className="col-12">                                    
                                                        <MiDataTable 
                                                            estadoForm= { "" }
                                                            formulario={ {id:0} }
                                                            id={"barcosadmin"}
                                                            columnas={admin.controles.barcos.datatable.columns}
                                                            data={datosTableBarcos}
                                                            linkColumns={admin.controles.barcos.datatable.linkColumns}
                                                            buttons={admin.controles.barcos.datatable.buttons}
                                                            ordering={admin.controles.barcos.datatable.ordering}
                                                            order={admin.controles.barcos.datatable.order}
                                                            searching={admin.controles.barcos.datatable.searching}
                                                            paging={admin.controles.barcos.datatable.paging}
                                                            select={admin.controles.barcos.datatable.select}
                                                            pagelength={admin.controles.barcos.datatable.pagelength}
                                                            // saveLoadingTable={ saveLoadingTable }
                                                            loadingTable={loadingTable}
                                                            columnactions={admin.controles.barcos.datatable.columnactions.title}
                                                            traduction={traduction.datatable}
                                                            // functionClickTd={ goToKpis }
                                                            // columnsFunctionClickTd={[1]}
                                                        />                          
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </Fragment>
        {/* ********* MODAL PARA CLICK EN LA TABLA MANTENIMIENTOS PENDIENTES ********* */}
        {
            showModalMatenimientosPendientes
            ?
                <Modal
                    size="lg"
                    show={true}
                    onHide={ () => saveShowModalMantenimientosPendientes(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}
                    contentClassName="likeSw"
                    backdropClassName="backLikeSw"                                              
                >                          
                    <Modal.Body>
                        <div className="swal-icon swal-icon--warning">
                            <span className="swal-icon--warning__body">
                            <span className="swal-icon--warning__dot"></span>
                            </span>
                        </div>                        
                        <div className="swal-text">{admin.controles.plantillas.plantilla.swal.text.title}</div>
                        <h6 className='text-center' dangerouslySetInnerHTML={{ __html: descripcionDocu }}></h6>
                        <MiSelect  
                            estadoForm=  { "" }
                            val=         { valTrabajador }   
                            loading=     { false }
                            id=          { confPantalla.documentos.padre.controles.idage2.id }    
                            name=        { confPantalla.documentos.padre.controles.idage2.name }   
                            label=       { confPantalla.documentos.padre.controles.idage2.label }    
                            placeholder= { confPantalla.documentos.padre.controles.idage2.placeholder }
                            col=         { 12 } 
                            isClearable= { confPantalla.documentos.padre.controles.idage2.isclearable }
                            isSearchable={ confPantalla.documentos.padre.controles.idage2.issearchable }
                            dataType=    { confPantalla.documentos.padre.controles.idage2.datatype }
                            data=        { confPantalla.documentos.padre.controles.idage2.data }
                            required=    { false } 
                            disabled=    { false }
                            readOnly =   { false }
                            disablethisid={false}
                            disableids=   {false}
                            formulario= { {id:0} }   
                            change= { handleSelectTrabajador }
                        />
                        <FormInput                                                        
                            estadoForm= { "" }
                            value=      { valFec1 }
                            loading=    { false }
                            id=         { confPantalla.documentos.padre.controles.fec1.id }    
                            type=       { "date" }  
                            name=       { confPantalla.documentos.padre.controles.fec1.name }   
                            label=      { confPantalla.documentos.padre.controles.fec1.label }                                                       
                            placeholder={ confPantalla.documentos.padre.controles.fec1.placeholder }    
                            col=        { "12" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { handleFec1 }
                            onBlur=     {null} 
                        />
                        <div className='form-group col-md-12'>
                            <Informes
                                select =     { admin.controles.plantillas.informes.select }
                                formulario = { {id: idMantenimientos} }
                                getRegistro = { admin.controles.plantillas.informes.getregistro }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnGenerar= { admin.controles.plantillas.informes.btngenerar.title }
                            />
                        </div>
                        <div className='text-right'>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosPendientes(0) }                                        
                            >
                                {admin.controles.plantillas.plantilla.swal.btncancel.title}
                            </button>
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ valTrabajador == "0" || valTrabajador == null || valTrabajador == "null" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosPendientes(1) }
                            >
                                {admin.controles.plantillas.plantilla.swal.btnconfirm.title}
                            </button>
                            <button 
                                className="swal-button swal-button--verplant btn btn-flat mr-1 mt-1 btn-outline-warning btnSwalWarning"
                                onClick={ e => actionsModalMantenimientosPendientes(2) }
                            >
                                {admin.controles.plantillas.plantilla.swal.btnverplant.title}
                            </button>                                                                                          
                        </div>
                    </Modal.Body>                            
                </Modal>                
            :
                null
        }
        {/* ********* MODAL PARA CLICK EN LA TABLA MANTENIMIENTOS CORRECTIVOS ********* */}
        {
            showModalMatenimientosCorrectivos
            ?
                <Modal
                    size="lg"
                    show={true}
                    onHide={ () => saveShowModalMantenimientosCorrectivos(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}
                    contentClassName="likeSw"
                    backdropClassName="backLikeSw"                                              
                >                          
                    <Modal.Body>
                        <div className="swal-icon swal-icon--warning">
                            <span className="swal-icon--warning__body">
                            <span className="swal-icon--warning__dot"></span>
                            </span>
                        </div>                        
                        <div className="swal-text">{admin.controles.createcorrectivo.swal.text.title}</div>
                        <h6 className='text-center' dangerouslySetInnerHTML={{ __html: descripcionDocu }}></h6>
                        <MiSelect  
                            estadoForm=  { "" }
                            val=         { valTrabajador }   
                            loading=     { false }
                            id=          { confPantalla.documentos.padre.controles.idage2.id }    
                            name=        { confPantalla.documentos.padre.controles.idage2.name }   
                            label=       { confPantalla.documentos.padre.controles.idage2.label }    
                            placeholder= { confPantalla.documentos.padre.controles.idage2.placeholder }
                            col=         { 12 } 
                            isClearable= { confPantalla.documentos.padre.controles.idage2.isclearable }
                            isSearchable={ confPantalla.documentos.padre.controles.idage2.issearchable }
                            dataType=    { confPantalla.documentos.padre.controles.idage2.datatype }
                            data=        { confPantalla.documentos.padre.controles.idage2.data }
                            required=    { false } 
                            disabled=    { false }
                            readOnly =   { false }
                            disablethisid={false}
                            disableids=   {false}
                            formulario= { {id:0} }   
                            change= { handleSelectTrabajador }
                        />
                        <FormInput                                                        
                            estadoForm= { "" }
                            value=      { valFec1 }
                            loading=    { false }
                            id=         { confPantalla.documentos.padre.controles.fec1.id }    
                            type=       { "date" }  
                            name=       { confPantalla.documentos.padre.controles.fec1.name }   
                            label=      { confPantalla.documentos.padre.controles.fec1.label }                                                       
                            placeholder={ confPantalla.documentos.padre.controles.fec1.placeholder }    
                            col=        { "12" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { handleFec1 }
                            onBlur=     {null} 
                        />
                        <div className='form-group col-md-12'>
                            <Informes
                                select =     { admin.controles.createcorrectivo.informes.select }
                                formulario = { {id: idMantenimientosCorrectivos} }
                                getRegistro = { admin.controles.createcorrectivo.informes.getregistro }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnGenerar= { admin.controles.createcorrectivo.informes.btngenerar.title }
                            />
                        </div>
                        <div className='text-right'>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosCorrectivos(0) }                                        
                            >
                                {admin.controles.createcorrectivo.swal.btncancel.title}
                            </button>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosCorrectivos(4) }
                            >
                                {admin.controles.createcorrectivo.swal.comprobar.title}
                            </button>
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ valTrabajador == "0" || valTrabajador == null || valTrabajador == "null" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosCorrectivos(1) }
                            >
                                {admin.controles.createcorrectivo.swal.btnconfirm.title}
                            </button>
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ !isPlanPreventivo || valTrabajador == "0" || valTrabajador == null || valTrabajador == "null" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosCorrectivos(2) }
                            >
                                {admin.controles.createcorrectivo.swal.btncreateprev.title}
                            </button>
                            <button 
                                className="swal-button swal-button--verplant btn btn-flat mr-1 mt-1 btn-outline-warning btnSwalWarning"
                                onClick={ e => actionsModalMantenimientosCorrectivos(3) }
                            >
                                {admin.controles.createcorrectivo.swal.btnverplant.title}
                            </button>
                            {
                                checkeando == true
                                ?
                                    <div className='mt-2 alert alert-primary'  role='alert' style={{display: "flex", justifyContent: "space-around"}}>
                                        <Spinner animation="border" />                                    
                                    </div>
                                :
                                    null
                            }
                            {
                                checkear == true
                                ?
                                    <div className='mt-2 alert alert-primary text-left'  role='alert' /*style={{display: "flex", justifyContent: "space-around"}}*/ >
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkEstado == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkEstado == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {admin.controles.createcorrectivo.checkear.estado.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkEstadoNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkFecha == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkFecha == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {admin.controles.createcorrectivo.checkear.fecha.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkFechaNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkCentro == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkCentro == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            } 
                                            {admin.controles.createcorrectivo.checkear.centro.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkCentroNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkRealizado == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkRealizado == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {admin.controles.createcorrectivo.checkear.realizado.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkRealizadoNom}</span>
                                            </p>
                                        </div>
                                    </div>                                                                                          
                                :
                                    null
                            }
                              {
                                errorCheckeando != false
                                ?
                                    <div className='mt-2 alert alert-danger'  role='alert' style={{display: "flex", justifyContent: "space-around"}}>
                                        <div>
                                            <h5>{errorCheckeando}</h5>
                                        </div>                                     
                                    </div>                                                                                          
                                :
                                    null
                            }
                        </div>
                    </Modal.Body>                            
                </Modal>                
            :
                null
        }                
        </SRLWrapper>
    )
}

export default InicioAdmin;
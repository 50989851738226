import React, { useEffect, useContext, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import UplmPlus from '../../componentes/formulario/UplmPlus/UplmPlus';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';
import Informes from '../../componentes/formulario/informes/Informes';
import MiDataTableAdjLin from '../../componentes/formulario/MiDataTableAdjLin/MiDataTableAdjLin';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost, getTitle } from '../../helpers/helperApp';

function Documentos(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey, tipDocPoeArray } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo, handleSelectMultipleFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.documentos.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.documentos.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.documentos.hijo.estadoform);                            
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.documentos.padre.urls);
                                saveFormularioPrin(confPantalla.documentos.padre.modelform);
                                saveFormularioHijo(confPantalla.documentos.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.documentos.padre.urls, confPantalla.documentos.hijo.urls, confPantalla.documentos.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
        } 

    },[confPantalla])


    const getEsTraspasoAndHandleSelect = async (selectedItem, nameOfComponent) => {

        // Hacemos una llamada para saber si el tipo de documento es de tipo traspado para mostrar u ocultar la ubicación 2
        if( selectedItem ){
            const data = new FormData();
            data.append("id", selectedItem.value);
            const validacion = apiKey();
            data.append("validacion", validacion);
            const url = `${getApiUrl(window.location.origin)}/documentos/getTipoStock`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(url, data, source.token);
            
            if( res.data[0].sto == 4){
                saveFormularioPrin(
                    {
                        ... formularioPrin, 
                        ["tipostock"] : res.data[0].sto,
                        [nameOfComponent.name]:selectedItem.value                        
                    }
                )
            }else{
                saveFormularioPrin(
                    {
                        ... formularioPrin, 
                        ["tipostock"] : "",
                        [nameOfComponent.name]:selectedItem.value                         
                    }
                )
            }            
           
        }else{
            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["tipostock"] : ""                       
                }
            )

        }

    }

    const handleUnidadAndSetDescription = async (selectedItem, nameOfComponent, setValue) => {

            if( selectedItem ){
                const data = new FormData();
                data.append("id", selectedItem.value);
                data.append("validacion", apiKey());
                const url = `${getApiUrl(window.location.origin)}/articulos/getDescripciones`;
                // const res = await axios.post(url, data);
                const res = await axiosPost(url, data, source2.token);
                handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, setValue, res.data[0].nom1, res.data[0].nom2, res.data[0].cod1);
            }else{
                handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, setValue, "", "", "");

            }
    }

    return(
        <div className="App wrapper"> 
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                // titulo={ confPantalla.documentos.section.titulo } 
                                titulo={ 
                                    formularioPrin.id == "" ?
                                        confPantalla.documentos.section.titulo
                                    :
                                        cookies.get('idioma').toLowerCase() == "es".toLowerCase() ?
                                            formularioPrin.tipdocnom+" - "+formularioPrin.nom1
                                        :
                                            cookies.get('idioma').toLowerCase() == "en".toLowerCase() ?
                                                formularioPrin.tipdocnom+" - "+formularioPrin.nom1_en
                                            :
                                                formularioPrin.tipdocnom+" - "+formularioPrin.nom1+" - "+formularioPrin.nom1_en
                                } 
                                icono={ confPantalla.documentos.section.icono } 
                                col={ confPantalla.documentos.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                                borderHeader={ formularioPrin.color }
                                recurrencia={formularioPrin.recurrencia}
                                getFirmas={confPantalla.documentos.padre.controles.getfirmas}
                                traduction={confPantalla.config.trad.components}
                                formulario={formularioPrin}
                        >
                                <Tabs defaultActiveKey="tab1" id="tab1">
                                    <Tab eventKey="tab1" title={ confPantalla.documentos.tabs.tab1.titulo }>
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.documentos.padre.btnform.new }
                                                    btnSave={ confPantalla.documentos.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.documentos.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.documentos.padre.btnform.delete }
                                                    btnClone={ confPantalla.documentos.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.modelform, confPantalla.documentos.padre.tabla, confPantalla.documentos.hijo.modelform, confPantalla.documentos.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.documentos.padre.modelform, true, confPantalla.documentos.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.modelform, confPantalla.documentos.padre.tabla, confPantalla.documentos.title) } 
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.documentos.padre.urls, confPantalla.documentos.hijo.urls, confPantalla.documentos.padreshijos, confPantalla.documentos.hijo.modelform, confPantalla.documentos.padre.tabla, confPantalla.documentos.title) } 

                                        >
                                        <Informes
                                            select =     { confPantalla.documentos.padre.controles.informes.select }
                                            formulario = { formularioPrin }
                                            loadReg =    { loadReg ? true : false }
                                            estadoForm=  { estadoFormPrin } 
                                            traduction=  { confPantalla.config.trad.components }
                                        />
                                        <hr className="separator" />
                                            <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                <Tab eventKey="tab1_1" title={ confPantalla.documentos.tabs.tab1.subtabs.tab11.titulo }>
                                                     <FormRow clases="" >
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idtip  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idtip.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idtip.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idtip.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idtip.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idtip.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idtip.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idtip.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idtip.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idtip.data }
                                                            required=    { confPantalla.documentos.padre.controles.idtip.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idtip.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idtip.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idtip.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idtip.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { getEsTraspasoAndHandleSelect }
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.cod.id }   
                                                            type=       { confPantalla.documentos.padre.controles.cod.type }  
                                                            name=       { confPantalla.documentos.padre.controles.cod.name }   
                                                            label=      { confPantalla.documentos.padre.controles.cod.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.cod.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.cod.col } 
                                                            required=   { confPantalla.documentos.padre.controles.cod.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.cod.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.cod.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idest  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idest.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idest.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idest.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idest.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idest.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idest.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idest.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idest.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idest.data }
                                                            required=    { confPantalla.documentos.padre.controles.idest.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idest.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idest.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idest.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idest.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />  
                                                    </FormRow>                                       
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idage1  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idage1.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idage1.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idage1.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idage1.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idage1.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idage1.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idage1.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idage1.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idage1.data }
                                                            required=    { confPantalla.documentos.padre.controles.idage1.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idage1.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idage1.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idage1.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idage1.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idage2  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idage2.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idage2.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idage2.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idage2.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idage2.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idage2.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idage2.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idage2.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idage2.data }
                                                            required=    { confPantalla.documentos.padre.controles.idage2.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idage2.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idage2.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idage2.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idage2.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_documentos_gf  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.rel_documentos_gf.id }    
                                                            name=        { confPantalla.documentos.padre.controles.rel_documentos_gf.name }   
                                                            label=       { confPantalla.documentos.padre.controles.rel_documentos_gf.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.rel_documentos_gf.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.rel_documentos_gf.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.rel_documentos_gf.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.rel_documentos_gf.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.rel_documentos_gf.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.rel_documentos_gf.data }
                                                            required=    { confPantalla.documentos.padre.controles.rel_documentos_gf.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.rel_documentos_gf.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.rel_documentos_gf.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.rel_documentos_gf.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />                                                        
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.nom1.id }   
                                                            type=       { confPantalla.documentos.padre.controles.nom1.type }  
                                                            name=       { confPantalla.documentos.padre.controles.nom1.name }   
                                                            label=      { confPantalla.documentos.padre.controles.nom1.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.nom1.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.nom1.col } 
                                                            required=   { confPantalla.documentos.padre.controles.nom1.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.nom1.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.nom1.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom1_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.nom1_en.id }   
                                                            type=       { confPantalla.documentos.padre.controles.nom1_en.type }  
                                                            name=       { confPantalla.documentos.padre.controles.nom1_en.name }   
                                                            label=      { confPantalla.documentos.padre.controles.nom1_en.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.nom1_en.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.nom1_en.col } 
                                                            required=   { confPantalla.documentos.padre.controles.nom1_en.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.nom1_en.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.nom1_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.fec1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.fec1.id }   
                                                            type=       { confPantalla.documentos.padre.controles.fec1.type }  
                                                            name=       { confPantalla.documentos.padre.controles.fec1.name }   
                                                            label=      { confPantalla.documentos.padre.controles.fec1.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.fec1.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.fec1.col } 
                                                            required=   { confPantalla.documentos.padre.controles.fec1.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.fec1.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.fec1.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.fec2 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.fec2.id }   
                                                            type=       { confPantalla.documentos.padre.controles.fec2.type }  
                                                            name=       { confPantalla.documentos.padre.controles.fec2.name }   
                                                            label=      { confPantalla.documentos.padre.controles.fec2.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.fec2.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.fec2.col } 
                                                            required=   { confPantalla.documentos.padre.controles.fec2.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.fec2.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.fec2.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.fec3 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.fec3.id }   
                                                            type=       { confPantalla.documentos.padre.controles.fec3.type }  
                                                            name=       { confPantalla.documentos.padre.controles.fec3.name }   
                                                            label=      { confPantalla.documentos.padre.controles.fec3.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.fec3.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.fec3.col } 
                                                            required=   { confPantalla.documentos.padre.controles.fec3.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.fec3.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.fec3.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idcen  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idcen.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idcen.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idcen.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idcen.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idcen.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idcen.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idcen.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idcen.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idcen.data }
                                                            required=    { confPantalla.documentos.padre.controles.idcen.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idcen.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idcen.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idcen.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idcen.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idequ  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idequ.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idequ.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idequ.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idequ.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idequ.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idequ.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idequ.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idequ.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idequ.data }
                                                            required=    { confPantalla.documentos.padre.controles.idequ.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idequ.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idequ.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idequ.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idequ.disableids}
                                                            depends={ confPantalla.documentos.padre.controles.idequ.depends }
                                                            formulario= { formularioPrin }
                                                            change= { (selectedItem, nameOfComponent,) => handleSelectFormPrinAndSetValue(selectedItem, nameOfComponent, confPantalla.documentos.padre.controles.idequ.setvalue) }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idapa  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idapa.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idapa.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idapa.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idapa.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idapa.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idapa.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idapa.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idapa.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idapa.data }
                                                            required=    { confPantalla.documentos.padre.controles.idapa.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idapa.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idapa.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idapa.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idapa.disableids}
                                                            formulario= { formularioPrin }
                                                            change= { handleSelectFormPrin }
                                                        />    
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idubi  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentos.padre.controles.idubi.id }    
                                                            name=        { confPantalla.documentos.padre.controles.idubi.name }   
                                                            label=       { confPantalla.documentos.padre.controles.idubi.label }    
                                                            placeholder= { confPantalla.documentos.padre.controles.idubi.placeholder }
                                                            col=         { confPantalla.documentos.padre.controles.idubi.col } 
                                                            isClearable= { confPantalla.documentos.padre.controles.idubi.isclearable }
                                                            isSearchable={ confPantalla.documentos.padre.controles.idubi.issearchable }
                                                            dataType=    { confPantalla.documentos.padre.controles.idubi.datatype }
                                                            data=        { confPantalla.documentos.padre.controles.idubi.data }
                                                            required=    { confPantalla.documentos.padre.controles.idubi.required } 
                                                            disabled=  { confPantalla.documentos.padre.controles.idubi.disabled }
                                                            readOnly =   { confPantalla.documentos.padre.controles.idubi.readonly }
                                                            disablethisid={confPantalla.documentos.padre.controles.idubi.disablethisid}
                                                            disableids={confPantalla.documentos.padre.controles.idubi.disableids}
                                                            depends={ confPantalla.documentos.padre.controles.idubi.depends }
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                        {
                                                            formularioPrin.tipostock == "4"
                                                            ?
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormPrin }
                                                                val=         { formularioPrin.idubi2  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.documentos.padre.controles.idubi2.id }    
                                                                name=        { confPantalla.documentos.padre.controles.idubi2.name }   
                                                                label=       { confPantalla.documentos.padre.controles.idubi2.label }    
                                                                placeholder= { confPantalla.documentos.padre.controles.idubi2.placeholder }
                                                                col=         { confPantalla.documentos.padre.controles.idubi2.col } 
                                                                isClearable= { confPantalla.documentos.padre.controles.idubi2.isclearable }
                                                                isSearchable={ confPantalla.documentos.padre.controles.idubi2.issearchable }
                                                                dataType=    { confPantalla.documentos.padre.controles.idubi2.datatype }
                                                                data=        { confPantalla.documentos.padre.controles.idubi2.data }
                                                                required=    { confPantalla.documentos.padre.controles.idubi2.required } 
                                                                disabled=  { confPantalla.documentos.padre.controles.idubi2.disabled }
                                                                readOnly =   { confPantalla.documentos.padre.controles.idubi2.readonly }
                                                                disablethisid={confPantalla.documentos.padre.controles.idubi2.disablethisid}
                                                                disableids={confPantalla.documentos.padre.controles.idubi2.disableids}
                                                                depends={ confPantalla.documentos.padre.controles.idubi2.depends }
                                                                formulario= { formularioPrin }   
                                                                change= { handleSelectFormPrin }
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </FormRow>                                                  
                                                </Tab>                                                
                                                <Tab eventKey="tab1_2" title={ confPantalla.documentos.tabs.tab1.subtabs.tab12.titulo }>
                                                    <TextArea                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.notas }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.documentos.padre.controles.notas.id }   
                                                        name=       { confPantalla.documentos.padre.controles.notas.name }
                                                        rows=       { confPantalla.documentos.padre.controles.notas.rows }      
                                                        label=      { confPantalla.documentos.padre.controles.notas.label }                                                       
                                                        placeholder={ confPantalla.documentos.padre.controles.notas.placeholder }    
                                                        col=        { confPantalla.documentos.padre.controles.notas.col } 
                                                        required=   { confPantalla.documentos.padre.controles.notas.required }
                                                        readonly=   { confPantalla.documentos.padre.controles.notas.readonly }                                                
                                                        disabled=   { confPantalla.documentos.padre.controles.notas.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                    <TextArea                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.notas_en }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.documentos.padre.controles.notas_en.id }   
                                                        name=       { confPantalla.documentos.padre.controles.notas_en.name }
                                                        rows=       { confPantalla.documentos.padre.controles.notas_en.rows }      
                                                        label=      { confPantalla.documentos.padre.controles.notas_en.label }                                                       
                                                        placeholder={ confPantalla.documentos.padre.controles.notas_en.placeholder }    
                                                        col=        { confPantalla.documentos.padre.controles.notas_en.col } 
                                                        required=   { confPantalla.documentos.padre.controles.notas_en.required }
                                                        readonly=   { confPantalla.documentos.padre.controles.notas_en.readonly }                                                
                                                        disabled=   { confPantalla.documentos.padre.controles.notas_en.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                </Tab>
                                                {/* <Tab eventKey="tab1_3" title={ confPantalla.documentos.tabs.tab1.subtabs.tab13.titulo }>                                                  
                                                </Tab> */}
                                                <Tab eventKey="tab1_4" title={ confPantalla.documentos.tabs.tab1.subtabs.tab14.titulo }>                                                  
                                                    <FormRow clases="" >
                                                        {/* <Uplm
                                                            estadoForm= { estadoFormPrin }
                                                            loading=    { loadReg ? true : false }
                                                            col={12}
                                                            id={ confPantalla.documentos.padre.controles.uplm.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            readonly={ confPantalla.documentos.padre.controles.uplm.readonly }
                                                            traduction={confPantalla.config.trad.components.uplm}
                                                        /> */}
                                                        <UplmPlus2
                                                            id={ confPantalla.documentos.padre.controles.uplmplus.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            estadoForm= { estadoFormPrin }
                                                            loading= { loadReg ? true : false }
                                                            col={12}
                                                            readonly={ confPantalla.documentos.padre.controles.uplmplus.readonly }
                                                            traduction={confPantalla.config.trad.components}                                                            
                                                            datatable={ confPantalla.documentos.padre.controles.uplmplus.datatable }
                                                            select={ confPantalla.documentos.padre.controles.uplmplus.select }
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                <Tab eventKey="tab1_8" title={ confPantalla.documentos.tabs.tab1.subtabs.tab18.titulo }>                                                  
                                                    <FormRow clases="" >
                                                        <MiDataTableAdjLin 
                                                            id =         { confPantalla.documentos.padre.controles.tableadjlin.id }     
                                                            loading=     { loadReg ? true : false }
                                                            formulario = { formularioPrin }
                                                            handleEditReg= { e => handleEditRegHijo(e, confPantalla.documentos.hijo.urls, confPantalla.documentos.hijo.tabla) }
                                                            table =      { confPantalla.documentos.padre.controles.tableadjlin }
                                                            traduction=  { confPantalla.config.trad.components }
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                {/* <Tab eventKey="tab1_5" title={ confPantalla.documentos.tabs.tab1.subtabs.tab15.titulo }>
                                                    <Informes
                                                        select =     { confPantalla.documentos.padre.controles.informes.select }
                                                        formulario = { formularioPrin }
                                                        loadReg =    { loadReg ? true : false }
                                                        estadoForm=  { estadoFormPrin } 
                                                        traduction=  { confPantalla.config.trad.components }
                                                    />                                                  
                                                </Tab> */}
                                                {/* <Tab eventKey="tab1_6" title={ confPantalla.documentos.tabs.tab1.subtabs.tab16.titulo }> */}
                                                <div className='noDisplay'>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iin"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iin.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.iin.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.iin.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iin.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iin.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iin.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iin.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iin.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iin.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iin"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iin.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.iin.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iin.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iin.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iin.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iin.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iin.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iin.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin["valoracion.cos"] }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentos.padre.controles.cos.id }   
                                                            type=       { confPantalla.documentos.padre.controles.cos.type }  
                                                            name=       { confPantalla.documentos.padre.controles.cos.name }   
                                                            label=      { confPantalla.documentos.padre.controles.cos.label }                                                       
                                                            placeholder={ confPantalla.documentos.padre.controles.cos.placeholder }    
                                                            col=        { confPantalla.documentos.padre.controles.cos.col } 
                                                            required=   { confPantalla.documentos.padre.controles.cos.required }
                                                            readonly=   { confPantalla.documentos.padre.controles.cos.readonly }                                                
                                                            disabled=   { confPantalla.documentos.padre.controles.cos.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null}
                                                            alignRight= { true }
 
                                                        />                                           
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        { 
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.und"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.und.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.und.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.und.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.und.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.und.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.und.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.und.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.und.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.und.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.und"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.und.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.und.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.und.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.und.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.und.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.und.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.und.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.und.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.pre"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.pre.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.pre.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.pre.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.pre.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.pre.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.pre.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.pre.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.pre.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.pre.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null}
                                                                    alignRight= { true } 
                                                                />                                           
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.pre"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.pre.id }   
                                                                    type=       { "hidden"}  
                                                                    name=       { confPantalla.documentos.padre.controles.pre.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.pre.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.pre.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.pre.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.pre.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.pre.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.pre.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.imp1.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.imp1.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.imp1.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.imp1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.imp1.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.imp1.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.imp1.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.imp1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.imp1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.imp1.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.imp1.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.imp1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.imp1.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.imp1.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.imp1.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.imp1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.imp1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.mar1.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.mar1.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.mar1.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.mar1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.mar1.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.mar1.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.mar1.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.mar1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.mar1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.mar1.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.mar1.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.mar1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.mar1.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.mar1.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.mar1.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.mar1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.mar1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.dto"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.dto.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.dto.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.dto.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.dto.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.dto.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.dto.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.dto.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.dto.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.dto.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.dto"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.dto.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.dto.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.dto.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.dto.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.dto.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.dto.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.dto.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.dto.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.impd"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.impd.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.impd.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.impd.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.impd.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.impd.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.impd.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.impd.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.impd.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.impd.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.impd"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.impd.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.impd.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.impd.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.impd.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.impd.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.impd.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.impd.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.impd.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.imp2.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.imp2.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.imp2.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.imp2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.imp2.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.imp2.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.imp2.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.imp2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.imp2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.imp2.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.imp2.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.imp2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.imp2.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.imp2.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.imp2.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.imp2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.imp2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.mar2.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.mar2.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.mar2.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.mar2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.mar2.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.mar2.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.mar2.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.mar2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.mar2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.mar2.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.mar2.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.mar2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.mar2.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.mar2.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.mar2.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.mar2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.mar2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iva"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iva.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.iva.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.iva.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iva.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iva.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iva.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iva.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iva.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iva.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iva"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iva.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.iva.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iva.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iva.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iva.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iva.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iva.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iva.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iiv"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iiv.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.iiv.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.iiv.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iiv.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iiv.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iiv.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iiv.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iiv.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iiv.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iiv"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iiv.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.iiv.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iiv.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iiv.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iiv.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iiv.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iiv.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iiv.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.rec"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.rec.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.rec.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.rec.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.rec.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.rec.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.rec.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.rec.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.rec.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.rec.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.rec"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.rec.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.rec.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.rec.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.rec.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.rec.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.rec.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.rec.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.rec.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iir"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iir.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.iir.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.iir.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iir.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iir.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iir.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iir.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iir.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iir.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iir"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iir.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.iir.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iir.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iir.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iir.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iir.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iir.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iir.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.ret"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.ret.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.ret.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.ret.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.ret.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.ret.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.ret.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.ret.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.ret.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.ret.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.ret"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.ret.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.ret.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.ret.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.ret.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.ret.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.ret.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.ret.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.ret.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iit"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iit.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.iit.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.iit.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iit.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iit.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iit.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iit.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iit.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iit.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null}
                                                                    alignRight= { true } 
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iit"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.iit.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.iit.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.iit.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.iit.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.iit.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.iit.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.iit.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.iit.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="text-right">
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.imp3.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.imp3.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.imp3.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.imp3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.imp3.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.imp3.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.imp3.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.imp3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.imp3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.imp3.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.imp3.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.imp3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.imp3.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.imp3.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.imp3.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.imp3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.imp3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />

                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.mar3.id }   
                                                                    type=       { confPantalla.documentos.padre.controles.mar3.type }  
                                                                    name=       { confPantalla.documentos.padre.controles.mar3.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.mar3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.mar3.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.mar3.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.mar3.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.mar3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.mar3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.padre.controles.mar3.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentos.padre.controles.mar3.name }   
                                                                    label=      { confPantalla.documentos.padre.controles.mar3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.padre.controles.mar3.placeholder }    
                                                                    col=        { confPantalla.documentos.padre.controles.mar3.col } 
                                                                    required=   { confPantalla.documentos.padre.controles.mar3.required }
                                                                    readonly=   { confPantalla.documentos.padre.controles.mar3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.padre.controles.mar3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                        }
                                                    </FormRow>
                                                </div>
                                                {/* </Tab> */}
                                                <Tab eventKey="tab1_7" title={ confPantalla.documentos.tabs.tab1.subtabs.tab17.titulo }> 
                                                    <Recurrencia
                                                        estadoForm=        { estadoFormPrin }
                                                        loading =          { loadReg ? true : false }
                                                        formulario =       { formularioPrin }
                                                        changeSelect =     { handleSelectFormPrin }
                                                        saveFormulario =   { saveFormularioPrin }
                                                        handleInput =      { handleInputForm }                                                        
                                                        traduction=        { confPantalla.config.trad.components.recurrencia }
                                                    />                                                 
                                                </Tab>
                                                {
                                                    formularioPrin.id != ""
                                                    ?
                                                        <Tab title={
                                                                    <NavLink to={
                                                                            tipDocPoeArray.includes(formularioPrin.idtip) 
                                                                            ?"/edirappoe/"+formularioPrin.id 
                                                                            :"/edirap/"+formularioPrin.id}>
                                                                        {confPantalla.edirap.sidebarmenu.titulo}
                                                                        {/* <i className={"ml-1 "+confPantalla.edirap.sidebarmenu.icono}></i> */}
                                                                    </NavLink>
                                                                } 
                                                            tabClassName="btn btn-light">                                                        
                                                        </Tab>
                                                    :
                                                        null
                                                }
                                                {
                                                    formularioPrin.id != ""
                                                    ?
                                                        <Tab title={
                                                                    <Link to={"/historia/"+formularioPrin.id} >
                                                                        {confPantalla.documentos.tabs.tab1.subtabs.tab19.titulo}
                                                                        {/* <i className={"ml-1 "+confPantalla.edirap.sidebarmenu.icono}></i> */}
                                                                    </Link>
                                                                } 
                                                            tabClassName="btn btn-light">                                                        
                                                        </Tab>
                                                    :
                                                        null
                                                }
                                            </Tabs>
                                        </Formulario>                                     
                                        <MiDataTable 
                                            formulario={formularioPrin}
                                            estadoForm= { estadoFormPrin }
                                            id={"documentos"}
                                            columnas={confPantalla.documentos.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.documentos.padre.datatable.buttons}
                                            ordering={confPantalla.documentos.padre.datatable.ordering}
                                            order={confPantalla.documentos.padre.datatable.order}
                                            searching={confPantalla.documentos.padre.datatable.searching}
                                            paging={confPantalla.documentos.padre.datatable.paging}
                                            select={confPantalla.documentos.padre.datatable.select}
                                            pagelength={confPantalla.documentos.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.documentos.padre.urls, confPantalla.documentos.hijo.urls, confPantalla.documentos.padreshijos, confPantalla.documentos.hijo.modelform, confPantalla.documentos.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.modelform, confPantalla.documentos.padre.tabla, confPantalla.documentos.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.documentos.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                    </Tab>
                                    { formularioPrin.id != 0                         
                                        ? 
                                            <Tab eventKey="tab2" title={ confPantalla.documentos.tabs.tab2.titulo }>
                                            <Formulario 
                                                handleSubmit = { e => handleSubmitHijo(e, confPantalla.documentos.hijo.urls, confPantalla.documentos.hijo.modelform, confPantalla.documentos.hijo.tabla, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.tabla, confPantalla.documentos.title2) } 
                                                formulario = { formularioHijo } 
                                                resetForm = { e => resetFormHijo(confPantalla.documentos.hijo.modelform) } 
                                                handleDeleteItem = { e => handleDeleteItemHijo(undefined, confPantalla.documentos.hijo.urls, confPantalla.documentos.hijo.modelform, confPantalla.documentos.hijo.tabla, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.tabla, confPantalla.documentos.title2) }
                                                handleCloneItem = { e => handleCloneHijo(formularioHijo.id, confPantalla.documentos.hijo.urls, confPantalla.documentos.hijo.tabla, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.tabla, confPantalla.documentos.title2) }
                                                esHijo= { true }
                                                formularioPadre= { formularioPrin }  
                                            >
                                                <input type="hidden" name="iddoc" id="lineas.iddoc" value={ formularioPrin.id } />

                                                    <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                        <Tab eventKey="tab1_1" title={ confPantalla.documentos.tabs.tab2.subtabs.tab21.titulo }>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idage1  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.idage1.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.idage1.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.idage1.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.idage1.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.idage1.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.idage1.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.idage1.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.idage1.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.idage1.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.idage1.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.idage1.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.idage1.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.idage1.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.idage1.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idage2  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.idage2.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.idage2.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.idage2.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.idage2.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.idage2.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.idage2.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.idage2.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.idage2.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.idage2.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.idage2.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.idage2.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.idage2.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.idage2.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.idage2.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />                                                        
                                                            </FormRow>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idest == "0" || formularioHijo.idest == "" ? formularioPrin.idest : formularioHijo.idest  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.idest.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.idest.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.idest.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.idest.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.idest.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.idest.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.idest.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.idest.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.idest.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.idest.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.idest.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.idest.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.idest.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.idest.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />  
                                                            </FormRow>
                                                            <FormRow>
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.fec1 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.fec1.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.fec1.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.fec1.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.fec1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.fec1.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.fec1.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.fec1.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.fec1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.fec1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.fec2 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.fec2.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.fec2.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.fec2.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.fec2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.fec2.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.fec2.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.fec2.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.fec2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.fec2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.fec3 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.fec3.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.fec3.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.fec3.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.fec3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.fec3.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.fec3.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.fec3.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.fec3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.fec3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                            </FormRow>
                                                            <FormRow>
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.txt1 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.txt1.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.txt1.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.txt1.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.txt1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.txt1.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.txt1.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.txt1.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.txt1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.txt1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.txt2 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.txt2.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.txt2.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.txt2.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.txt2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.txt2.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.txt2.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.txt2.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.txt2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.txt2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.txt3 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.txt3.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.txt3.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.txt3.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.txt3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.txt3.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.txt3.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.txt3.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.txt3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.txt3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                            </FormRow>
                                                            <FormRow>
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.num1 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.num1.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.num1.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.num1.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.num1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.num1.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.num1.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.num1.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.num1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.num1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />                                                                
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.iduni  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.iduni.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.iduni.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.iduni.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.iduni.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.iduni.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.iduni.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.iduni.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.iduni.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.iduni.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.iduni.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.iduni.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.iduni.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.iduni.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.iduni.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    // change= { (selectedItem, nameOfComponent,) => handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, confPantalla.documentos.hijo.controles.iduni.setvalue) }
                                                                    change= { (selectedItem, nameOfComponent,) => handleUnidadAndSetDescription(selectedItem, nameOfComponent, confPantalla.documentosenc.hijo.controles.iduni.setvalue) }
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.num3 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.num3.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.num3.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.num3.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.num3.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.num3.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.num3.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.num3.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.num3.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.num3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />                                                           
                                                            </FormRow>
                                                            <FormRow>
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.nom1 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.nom1.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.nom1.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.nom1.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.nom1.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.nom1.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.nom1.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.nom1.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.nom1.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.nom1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.nom1_en }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.nom1_en.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.nom1_en.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.nom1_en.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.nom1_en.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.nom1_en.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.nom1_en.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.nom1_en.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.nom1_en.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.nom1_en.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    
                                                                    onBlur=     {null} 
                                                                />
                                                            </FormRow>
                                                            <FormRow> 
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioPrin.idequ  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.idequ.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.idequ.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.idequ.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.idequ.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.idequ.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.idequ.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.idequ.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.idequ.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.idequ.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.idequ.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.idequ.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.idequ.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.idequ.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.idequ.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.num2 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.num2.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.num2.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.num2.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.num2.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.num2.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.num2.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.num2.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.num2.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.num2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                            </FormRow>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioPrin.idapa  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.idapa.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.idapa.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.idapa.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.idapa.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.idapa.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.idapa.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.idapa.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.idapa.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.idapa.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.idapa.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.idapa.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.idapa.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.idapa.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.idapa.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                            </FormRow>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }                                                                    
                                                                    val=         { formularioHijo.idubi == "0" || formularioHijo.idubi == "" ? formularioPrin.idubi : formularioHijo.idubi  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentos.hijo.controles.idubi.id }    
                                                                    name=        { confPantalla.documentos.hijo.controles.idubi.name }   
                                                                    label=       { confPantalla.documentos.hijo.controles.idubi.label }    
                                                                    placeholder= { confPantalla.documentos.hijo.controles.idubi.placeholder }
                                                                    col=         { confPantalla.documentos.hijo.controles.idubi.col } 
                                                                    isClearable= { confPantalla.documentos.hijo.controles.idubi.isclearable }
                                                                    isSearchable={ confPantalla.documentos.hijo.controles.idubi.issearchable }
                                                                    dataType=    { confPantalla.documentos.hijo.controles.idubi.datatype }
                                                                    data=        { confPantalla.documentos.hijo.controles.idubi.data }
                                                                    required=    { confPantalla.documentos.hijo.controles.idubi.required } 
                                                                    disabled=  { confPantalla.documentos.hijo.controles.idubi.disabled }
                                                                    readOnly =   { confPantalla.documentos.hijo.controles.idubi.readonly }
                                                                    disablethisid={confPantalla.documentos.hijo.controles.idubi.disablethisid}
                                                                    disableids={confPantalla.documentos.hijo.controles.idubi.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                {
                                                                    formularioPrin.tipostock == "4"
                                                                    ?
                                                                    <MiSelect  
                                                                        estadoForm=  { estadoFormHijo }
                                                                        val=         { formularioPrin.idubi2  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.documentos.hijo.controles.idubi2.id }    
                                                                        name=        { confPantalla.documentos.hijo.controles.idubi2.name }   
                                                                        label=       { confPantalla.documentos.hijo.controles.idubi2.label }    
                                                                        placeholder= { confPantalla.documentos.hijo.controles.idubi2.placeholder }
                                                                        col=         { confPantalla.documentos.hijo.controles.idubi2.col } 
                                                                        isClearable= { confPantalla.documentos.hijo.controles.idubi2.isclearable }
                                                                        isSearchable={ confPantalla.documentos.hijo.controles.idubi2.issearchable }
                                                                        dataType=    { confPantalla.documentos.hijo.controles.idubi2.datatype }
                                                                        data=        { confPantalla.documentos.hijo.controles.idubi2.data }
                                                                        required=    { confPantalla.documentos.hijo.controles.idubi2.required } 
                                                                        disabled=  { confPantalla.documentos.hijo.controles.idubi2.disabled }
                                                                        readOnly =   { confPantalla.documentos.hijo.controles.idubi2.readonly }
                                                                        disablethisid={confPantalla.documentos.hijo.controles.idubi2.disablethisid}
                                                                        disableids={confPantalla.documentos.hijo.controles.idubi2.disableids}
                                                                        formulario= { formularioHijo }   
                                                                        change= { handleSelectFormHijo }
                                                                    />
                                                                    :
                                                                    null
                                                                }                                                                
                                                            </FormRow>                                                            
                                                        </Tab>
                                                        <Tab eventKey="tab1_2" title={ confPantalla.documentos.tabs.tab2.subtabs.tab22.titulo }>
                                                            <TextArea                                                        
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo.notas }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentos.hijo.controles.notas.id }   
                                                                name=       { confPantalla.documentos.hijo.controles.notas.name }
                                                                rows=       { confPantalla.documentos.hijo.controles.notas.rows }      
                                                                label=      { confPantalla.documentos.hijo.controles.notas.label }                                                       
                                                                placeholder={ confPantalla.documentos.hijo.controles.notas.placeholder }    
                                                                col=        { confPantalla.documentos.hijo.controles.notas.col } 
                                                                required=   { confPantalla.documentos.hijo.controles.notas.required }
                                                                readonly=   { confPantalla.documentos.hijo.controles.notas.readonly }                                                
                                                                disabled=   { confPantalla.documentos.hijo.controles.notas.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                            />
                                                            <TextArea                                                        
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo.notas_en }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentos.hijo.controles.notas_en.id }   
                                                                name=       { confPantalla.documentos.hijo.controles.notas_en.name }
                                                                rows=       { confPantalla.documentos.hijo.controles.notas_en.rows }      
                                                                label=      { confPantalla.documentos.hijo.controles.notas_en.label }                                                       
                                                                placeholder={ confPantalla.documentos.hijo.controles.notas_en.placeholder }    
                                                                col=        { confPantalla.documentos.hijo.controles.notas_en.col } 
                                                                required=   { confPantalla.documentos.hijo.controles.notas_en.required }
                                                                readonly=   { confPantalla.documentos.hijo.controles.notas_en.readonly }                                                
                                                                disabled=   { confPantalla.documentos.hijo.controles.notas_en.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                            />
                                                        </Tab>
                                                        <Tab eventKey="tab1_3" title={ confPantalla.documentos.tabs.tab2.subtabs.tab23.titulo }>
                                                            <FormRow clases="" >
                                                                {/* <Uplm
                                                                    estadoForm= { estadoFormHijo }
                                                                    loading=    { loadReg ? true : false }
                                                                    col={12}
                                                                    id={confPantalla.documentos.hijo.controles.uplm.id}
                                                                    adjuntos={ adjuntosHijo }
                                                                    saveAdjuntos= { saveAdjuntosHijo }  
                                                                    adjuntosExistentes= { adjuntosExistentesHijo } 
                                                                    saveAdjuntosExistentes= { saveAdjuntosExistentesHijo }          
                                                                    readonly={ confPantalla.documentos.hijo.controles.uplm.readonly }
                                                                    traduction={confPantalla.config.trad.components.uplm}
                                                                /> */}
                                                                <UplmPlus2
                                                                    id={ confPantalla.documentos.hijo.controles.uplmplus.id }
                                                                    adjuntos={ adjuntosHijo }
                                                                    saveAdjuntos= { saveAdjuntosHijo }  
                                                                    adjuntosExistentes= { adjuntosExistentesHijo } 
                                                                    saveAdjuntosExistentes= { saveAdjuntosExistentesHijo }
                                                                    estadoForm= { estadoFormHijo }
                                                                    loading= { loadReg ? true : false }
                                                                    col={12}
                                                                    readonly={ confPantalla.documentos.hijo.controles.uplmplus.readonly }
                                                                    traduction={confPantalla.config.trad.components}                                                            
                                                                    datatable={ confPantalla.documentos.hijo.controles.uplmplus.datatable }
                                                                    select={ confPantalla.documentos.hijo.controles.uplmplus.select }
                                                                />
                                                            </FormRow>
                                                        </Tab>
                                                        {/* <Tab eventKey="tab1_4" title={ confPantalla.documentos.tabs.tab2.subtabs.tab24.titulo }>
                                                        </Tab> */}
                                                        {/* <Tab eventKey="tab1_5" title={ confPantalla.documentos.tabs.tab2.subtabs.tab25.titulo }> */}
                                                        <div className='noDisplay'>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iin"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iin.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.iin.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iin.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iin.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iin.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iin.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iin.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iin.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iin.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iin"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iin.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iin.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iin.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iin.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iin.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iin.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iin.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iin.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />

                                                                }
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo["valoracion.cos"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentos.hijo.controles.cos.id }   
                                                                    type=       { confPantalla.documentos.hijo.controles.cos.type }  
                                                                    name=       { confPantalla.documentos.hijo.controles.cos.name }   
                                                                    label=      { confPantalla.documentos.hijo.controles.cos.label }                                                       
                                                                    placeholder={ confPantalla.documentos.hijo.controles.cos.placeholder }    
                                                                    col=        { confPantalla.documentos.hijo.controles.cos.col } 
                                                                    required=   { confPantalla.documentos.hijo.controles.cos.required }
                                                                    readonly=   { confPantalla.documentos.hijo.controles.cos.readonly }                                                
                                                                    disabled=   { confPantalla.documentos.hijo.controles.cos.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />                                           
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.und"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.und.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.und.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.und.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.und.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.und.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.und.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.und.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.und.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.und.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.und"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.und.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.und.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.und.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.und.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.und.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.und.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.und.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.und.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.pre"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.pre.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.pre.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.pre.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.pre.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.pre.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.pre.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.pre.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.pre.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.pre.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />                                           
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.pre"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.pre.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.pre.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.pre.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.pre.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.pre.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.pre.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.pre.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.pre.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.imp1"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.imp1.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.imp1.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.imp1.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.imp1.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.imp1.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.imp1.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.imp1.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.imp1.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.imp1.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.imp1"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.imp1.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.imp1.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.imp1.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.imp1.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.imp1.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.imp1.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.imp1.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.imp1.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.mar1"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.mar1.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.mar1.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.mar1.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.mar1.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.mar1.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.mar1.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.mar1.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.mar1.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.mar1.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.mar1"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.mar1.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.mar1.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.mar1.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.mar1.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.mar1.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.mar1.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.mar1.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.mar1.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.dto"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.dto.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.dto.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.dto.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.dto.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.dto.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.dto.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.dto.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.dto.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.dto.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.dto"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.dto.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.dto.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.dto.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.dto.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.dto.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.dto.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.dto.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.dto.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.impd"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.impd.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.impd.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.impd.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.impd.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.impd.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.impd.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.impd.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.impd.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.impd.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.impd"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.impd.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.impd.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.impd.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.impd.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.impd.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.impd.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.impd.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.impd.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.imp2"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.imp2.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.imp2.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.imp2.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.imp2.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.imp2.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.imp2.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.imp2.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.imp2.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.imp2.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.imp2"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.imp2.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.imp2.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.imp2.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.imp2.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.imp2.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.imp2.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.imp2.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.imp2.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.mar2"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.mar2.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.mar2.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.mar2.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.mar2.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.mar2.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.mar2.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.mar2.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.mar2.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.mar2.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.mar2"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.mar2.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.mar2.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.mar2.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.mar2.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.mar2.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.mar2.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.mar2.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.mar2.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iva"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iva.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.iva.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iva.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iva.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iva.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iva.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iva.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iva.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iva.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iva"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iva.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iva.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iva.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iva.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iva.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iva.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iva.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iva.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iiv"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iiv.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.iiv.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iiv.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iiv.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iiv.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iiv.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iiv.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iiv.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iiv.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iiv"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iiv.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iiv.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iiv.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iiv.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iiv.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iiv.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iiv.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iiv.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.rec"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.rec.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.rec.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.rec.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.rec.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.rec.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.rec.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.rec.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.rec.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.rec.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null}
                                                                            alignRight= { true } 
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.rec"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.rec.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.rec.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.rec.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.rec.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.rec.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.rec.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.rec.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.rec.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iir"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iir.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.iir.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iir.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iir.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iir.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iir.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iir.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iir.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iir.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iir"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iir.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iir.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iir.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iir.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iir.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iir.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iir.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iir.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.ret"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.ret.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.ret.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.ret.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.ret.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.ret.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.ret.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.ret.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.ret.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.ret.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.ret"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.ret.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.ret.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.ret.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.ret.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.ret.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.ret.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.ret.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.ret.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iit"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iit.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.iit.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iit.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iit.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iit.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iit.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iit.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iit.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iit.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.iit"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.iit.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.iit.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.iit.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.iit.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.iit.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.iit.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.iit.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.iit.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                            <FormRow clases="text-right">
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.imp3"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.imp3.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.imp3.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.imp3.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.imp3.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.imp3.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.imp3.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.imp3.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.imp3.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.imp3.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        />
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.imp3"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.imp3.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.imp3.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.imp3.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.imp3.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.imp3.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.imp3.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.imp3.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.imp3.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                                {
                                                                    cookies.get('nivel') === "1"
                                                                    ?
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.mar3"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.mar3.id }   
                                                                            type=       { confPantalla.documentos.hijo.controles.mar3.type }  
                                                                            name=       { confPantalla.documentos.hijo.controles.mar3.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.mar3.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.mar3.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.mar3.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.mar3.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.mar3.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.mar3.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                            alignRight= { true }
                                                                        /> 
                                                                    :
                                                                        <FormInput                                                        
                                                                            estadoForm= { estadoFormHijo }
                                                                            value=      { formularioHijo["valoracion.mar3"] }
                                                                            loading=    { loadReg ? true : false }
                                                                            id=         { confPantalla.documentos.hijo.controles.mar3.id }   
                                                                            type=       { "hidden" }  
                                                                            name=       { confPantalla.documentos.hijo.controles.mar3.name }   
                                                                            label=      { confPantalla.documentos.hijo.controles.mar3.label }                                                       
                                                                            placeholder={ confPantalla.documentos.hijo.controles.mar3.placeholder }    
                                                                            col=        { confPantalla.documentos.hijo.controles.mar3.col } 
                                                                            required=   { confPantalla.documentos.hijo.controles.mar3.required }
                                                                            readonly=   { confPantalla.documentos.hijo.controles.mar3.readonly }                                                
                                                                            disabled=   { confPantalla.documentos.hijo.controles.mar3.disabled }
                                                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                            onBlur=     {null} 
                                                                        />
                                                                }
                                                            </FormRow>
                                                        </div>
                                                        {/* </Tab>                                                        */}
                                                    </Tabs>
                                                    <div className="noDisplay">
                                                        <Recurrencia
                                                                estadoForm=        { estadoFormHijo }
                                                                loading =          { loadReg ? true : false }
                                                                formulario =       { formularioHijo }
                                                                changeSelect =     { handleSelectFormHijo }
                                                                saveFormulario =   { saveFormularioHijo }
                                                                handleInput =      { handleInputForm }                                                        
                                                                traduction=        { confPantalla.config.trad.components.recurrencia }
                                                                noDisplay=         { true }
                                                        /> 
                                                    </div>
                                                </Formulario>
                                                <MiDataTable 
                                                    formulario={formularioHijo} 
                                                    estadoForm= { estadoFormHijo }
                                                    id={"documentosHijo"}
                                                    columnas={confPantalla.documentos.hijo.datatable.columns}
                                                    data={registrosHijo}
                                                    buttons={confPantalla.documentos.hijo.datatable.buttons}
                                                    ordering={confPantalla.documentos.hijo.datatable.ordering}
                                                    order={confPantalla.documentos.hijo.datatable.order}
                                                    searching={confPantalla.documentos.hijo.datatable.searching}
                                                    paging={confPantalla.documentos.hijo.datatable.paging}
                                                    select={confPantalla.documentos.hijo.datatable.select}
                                                    pagelength={confPantalla.documentos.hijo.datatable.pagelength}
                                                    handleEditReg= { e => handleEditRegHijo(e, confPantalla.documentos.hijo.urls, confPantalla.documentos.hijo.tabla) }
                                                    handleDeleteItem={ e => handleDeleteItemHijo(e, confPantalla.documentos.hijo.urls, confPantalla.documentos.hijo.modelform, confPantalla.documentos.hijo.tabla, confPantalla.documentos.padre.urls, confPantalla.documentos.padre.tabla, confPantalla.documentos.title2) }
                                                    saveLoadingTable={ saveLoadingTable }
                                                    loadingTable={loadingTable}
                                                    columnactions={confPantalla.documentos.hijo.datatable.columnactions.title}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />                                                
                                            </Tab>
                                        :
                                        null
                                    }
                                    
                                </Tabs> 
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Documentos
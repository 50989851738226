import React from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function FormInput(props){

    const { id, type, name, label, placeholder, col, estadoForm, required, readonly, disabled, onBlur, change, loading, autoComplete, alignRight, title, keyUp, linkRight, linkLeft, linkColor, linkIco, clasesInput } = props;

    let { value } = props;

    if( value == null ){
        value = "";
    }

    $(`input[name='${name}']`).on('change invalid', function() {
        var textfield = $(this).get(0);
        
        // 'setCustomValidity not only sets the message, but also marks
        // the field as invalid. In order to see whether the field really is
        // invalid, we have to remove the message first
        if( title ){
            textfield.setCustomValidity('');
            
            if (!textfield.validity.valid) {
              textfield.setCustomValidity(title);  
            }
        }
    });
    

    return(
        <div className={"form-group col-md-"+col}>
            {
                type != "hidden"
                ?
                    <label htmlFor={id}>{label}</label>
                :
                null
            }
            <div className="input-group mb-2">
                {
                    linkLeft
                    ?
                        <div clclassNameass="input-group-prepend">
                            <NavLink to={linkLeft} className={"btn btn-"+linkColor}><i className={linkIco}></i></NavLink>
                        </div>
                    :
                        null
                }
                {
                    type == 'datetime-local' || type == 'datetime' || type == 'date'
                    ?
                        // // PROBAR A PONER DENTRO DE UN DIV Y METERLE EL ONBLUR, ONKEYUP AL DIV!!!!!!!!!!!!!!!
                        // <DatePicker 
                        //     className={ alignRight === true ? "form-control text-right "+clasesInput : "form-control "+clasesInput} 
                        //     id={ id } 
                        //     name={ name } 
                        //     // placeholder={ placeholder }
                        //     value={ value } 
                        //     onChange={ change } 
                        //     required={ required } 
                        //     disabled={ estadoForm === "disabled" ? true : disabled } 
                        //     // readOnly={ estadoForm === "readonly" || loading ? true : readonly } 
                        //     // autoComplete={ autoComplete === undefined ? "off" : autoComplete } 
                        //     onBlur={ onBlur !== null ? onBlur : null } 
                        //     // title = {placeholder}
                        //     onKeyUp={ keyUp !== null ? keyUp : null }
                        //     format={'y-MM-dd'}
                        //     showLeadingZeros={true}
                        // />
                        <input  type={ type } 
                            className={ alignRight === true ? "form-control text-right "+clasesInput : "form-control "+clasesInput} 
                            id={ id } 
                            name={ name } 
                            placeholder={ placeholder }
                            value={ value } 
                            onChange={ change } 
                            required={ required } 
                            disabled={ estadoForm === "disabled" ? true : disabled } 
                            readOnly={ estadoForm === "readonly" || loading ? true : readonly } 
                            autoComplete={ autoComplete === undefined ? "off" : autoComplete } 
                            onBlur={ onBlur !== null ? onBlur : null } 
                            title = {placeholder}
                            onKeyUp={ keyUp !== null ? keyUp : null }
                        />
                    :
                        <input  type={ type } 
                            className={ alignRight === true ? "form-control text-right "+clasesInput : "form-control "+clasesInput} 
                            id={ id } 
                            name={ name } 
                            placeholder={ placeholder }
                            value={ value } 
                            onChange={ change } 
                            required={ required } 
                            disabled={ estadoForm === "disabled" ? true : disabled } 
                            readOnly={ estadoForm === "readonly" || loading ? true : readonly } 
                            autoComplete={ autoComplete === undefined ? "off" : autoComplete } 
                            onBlur={ onBlur !== null ? onBlur : null } 
                            title = {placeholder}
                            onKeyUp={ keyUp !== null ? keyUp : null }
                        />
                }
                {
                    linkRight
                    ?
                        <div className="input-group-prepend" style={{marginLeft: "-1px"}}>
                            <NavLink to={linkRight} className={"btn btn-"+linkColor}  style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}><i className={linkIco}></i></NavLink>
                        </div>
                    :
                        null
                }
            </div>
        </div>
    )
}

export default FormInput;